import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  Typography,
  Box,
  useTheme,
  Grid,
  Button,
  FormControl,
  TextField,
} from "@mui/material";
import { API_URL } from "../../data/AppConstants";
import { tokens } from "../../theme";
import { useAuth } from "../../context/AuthContext";

const ModalUser = ({ handleCloseModal, mode, initialData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { isMobile } = useAuth();
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });

  const { register, handleSubmit, setValue, reset } = useForm({
    defaultValues: initialData || {
      userId: "",
      name: "",
      lastname: "",
      username: "",
      mail: "",
      password: "",
      phone: "",
      address: "",
      dni: "",
    },
  });

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const onSubmit = (data) => {
    const url =
      mode === "create" ? `${API_URL}user/create` : `${API_URL}user/edit`;
    const method = mode === "create" ? "POST" : "PUT";
    fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          showToast(data.message, "success");
          handleCloseModal();
        } else {
          showToast("Error en el servidor, intentelo más tarde", "error");
        }
      })
      .catch((err) => {
        console.log(err);
        showToast("Error en el servidor, intentelo más tarde", "error");
      });
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: colors.primary[800],
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
        minWidth: "400px",
        overflowY: isMobile ? "scroll" : "none",
        height: isMobile ? "80%" : "auto",
      }}
    >
      <Typography variant="h4" mb={2}>
        {mode === "create" ? "Crear Usuario" : "Editar Usuario"}
      </Typography>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            color="secondary" label="Nombre" {...register("name", { required: true })} />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            color="secondary"
            label="Apellidos"
            {...register("lastname", { required: true })}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            color="secondary" label="Correo" {...register("mail", { required: true })} />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            color="secondary"
            label="Usuario"
            {...register("username", { required: true })}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            color="secondary" label="DNI" {...register("dni")} />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            color="secondary" label="Teléfono" {...register("phone")} />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            color="secondary" label="Dirección" {...register("address")} />
        </FormControl>
        {mode === "create" && (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              color="secondary"
              label="Contraseña"
              type="password"
              {...register("password", { required: true })}
            />
          </FormControl>
        )}
        <Box display="flex" justifyContent="space-around" mt={3}>
          <Button variant="contained" color="secondary" type="submit">
            Guardar
          </Button>
          <Button variant="contained" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ModalUser;
