import React, { createContext, useContext, useState, useRef } from "react";
import Cookies from "js-cookie";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);
  const [loggedUserId, setLoggedUserId] = useState(null);
  const [permisos, setPermisos] = useState("");
  const isMobile =
    window.innerWidth <= 600 || /Mobi|Android/i.test(navigator.userAgent);
  const [mobileMenu, setMobileMenu] = useState(true);

  const login = (val, userId, permisos) => {
    setLoggedUserId(userId);
    setIsAdmin(val);
    setIsAuthenticated(true);
    setPermisos(permisos);
  };

  const logout = () => {
    Cookies.remove("isLoggedIn");
    Cookies.remove("isAdmin");
    Cookies.remove("userId");
    Cookies.remove("permisos");
    setIsAuthenticated(false);
    setLoggedUserId(null);
    setIsAdmin(true);
  };

  const toogleMobileMenu = (value) => setMobileMenu(value);

  const setUserId = (userId) => {
    setLoggedUserId(userId);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAdmin,
        loggedUserId,
        mobileMenu,
        isMobile,
        permisos,
        toogleMobileMenu,
        login,
        logout,
        setUserId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
