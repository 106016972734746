import { Box, useTheme, IconButton, Stack } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "@mui/material/Modal";
import { API_URL } from "../../data/AppConstants";
import { useAuth } from "../../context/AuthContext";
import AlertDialog from "../../components/ChooseDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CreateProductModal from "../../components/product/CreateProductModal";
import { Edit } from "@mui/icons-material";
import EditProductModal from "../../components/product/EditProductModal";

const Products = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [products, setProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState({ status: false, product: {} });
  const { isMobile } = useAuth();
  const [confirmDial, setConfirmDial] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });

  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleCloseToast = () => {
    setState({ ...state, open: false });
  };

  const fetchProducts = () => {
    fetch(`${API_URL}course/products`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          setProducts(data.products);
        } else {
          setProducts([]);
        }
      })
      .catch((err) => {
        setProducts([]);
        console.log(err);
      });
  };

  const handleDelete = () => {
    setConfirmDial(false);
    fetch(`${API_URL}payment/price?productId=${idToDelete}`, {
      method: "DELETE"
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          showToast("Producto eliminada exitosamente!", "success");
          fetchProducts();
        } else {
          showToast("Error en el servidor, intentelo más tarde", "error");
        }
      })
      .catch((err) => {
        showToast("Error en el servidor, intentelo más tarde", "error");
        console.log(err);
      });
  };
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options);
  };


  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsEditModalOpen({ status: false, product: {} });
    fetchProducts();
  };

  const handleCloseDial = () => {
    setConfirmDial(false);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: isMobile ? 1 : "none" },
    {
      field: "name",
      headerName: "Nombre",
      flex: isMobile ? "none" : 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "stripe_price_id",
      headerName: "Stripe Price Id (id para crear suscripcion)",
      flex: isMobile ? "none" : 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "product_price_id",
      headerName: "Stripe Product Id",
      flex: isMobile ? "none" : 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "recurring",
      headerName: "Periodicidad",
      flex: isMobile ? "none" : 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "amount",
      headerName: "Precio",
      flex: isMobile ? "none" : 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "created_at",
      headerName: "Fecha de creación",
      flex: isMobile ? "none" : 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => formatDate(params.value),
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 160,
      renderCell: ({ row }) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">

            <IconButton
              onClick={() => {
                setIsEditModalOpen({ status: true, product: row })

              }}
              color="secondary"
              aria-label="Ver"
            >
              <Edit />
            </IconButton>


            <IconButton
              onClick={() => {
                setIdToDelete(row.id);
                setConfirmDial(true);
              }}
              style={{ color: 'red' }}
              aria-label="Eliminar"
            >
              <DeleteIcon />
            </IconButton>

          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <Box m="20px">
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity={state.type}
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <Header
        title="Productos"
        subtitle="Gestiona la manera de pago de los cursos, este nombre no se mostrara al usuario, sera para tu control"
        showButton={true}
        handleOnClick={() => handleOpenModal("create")}
      />
      {
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <div>
            <CreateProductModal handleCloseModal={handleCloseModal} />
          </div>
        </Modal>
      }
      {
        <Modal open={isEditModalOpen.status} onClose={handleCloseModal}>
          <div>
            <EditProductModal handleCloseModal={handleCloseModal} product={isEditModalOpen.product} />
          </div>
        </Modal>
      }

      <AlertDialog
        open={confirmDial}
        handleClose={handleCloseDial}
        title={"¿Está seguro de borrar este producto?"}
        content={"No se podra recuperar"}
        handleConfirm={handleDelete}
      />
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: `${colors.primary[900]} !important`,
            fontWeight: 700,
            fontSize: "14px",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiTablePagination-toolbar": {
            color: "#000 !important",
          },
          "& .MuiDataGrid-selectedRowCount": {
            color: "#000 !important",
          },
        }}
      >
        <DataGrid
          rows={products}
          columns={columns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          components={{
            NoResultsOverlay: () => (
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center"
                zIndex="999"
                position="relative"
              >
                No hay resultados para el filtro aplicado
              </Stack>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default Products;
