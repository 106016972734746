import { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  Typography,
  Box,
  useTheme,
  FormControl,
  InputLabel,
  Input,
  Button,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Checkbox,
  Chip
} from "@mui/material";
import { API_URL } from "../../data/AppConstants";
import { tokens } from "../../theme";
import { useAuth } from "../../context/AuthContext";
import dayjs from "dayjs";
import esLocale from "dayjs/locale/es";
import { UploadFile } from "@mui/icons-material";
dayjs.locale(esLocale);

const ModalCreateSenal = ({ handleCloseModal, courses }) => {


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { isMobile } = useAuth();
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",

    usersForDropDown: [courses],

  });


  const showToast = (message, type) => {
    setState({ ...state, open: true, message, type });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const [formValues, setFormValues] = useState({
    courseId: "",
    title: "",
    description: "",
    video: ''
  });

  const handleChange = (event, newValue) => {

    const { name, value, type, checked } = event.target || {};
    console.log(newValue, name);
    // Handling standard input changes
    if (name) {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: type === "checkbox" ? checked : value,
      }));
    }

  };


  const handleCreateSignal = () => {
    if (formValues.title === "" || formValues.courseId === "") {
      showToast("Por favor, complete todos los campos.", "error");
    } else {
      const formData = new FormData();
      formData.append("courseId", formValues.courseId);
      formData.append("title", formValues.title);
      formData.append("description", formValues.description);
      if (formValues.video) {
        formData.append("videoFile", formValues.video);
      }
      console.log(formData.values())

      fetch(`${API_URL}admin/create-senal`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            showToast(data.message, "success");
            setTimeout(() => {
              handleCloseModal();
            }, 1000);
          } else {
            showToast(data.error, "error");
          }
        })
        .catch((err) => {
          console.log(err);
          showToast("Error en el servidor, intentelo más tarde", "error");
        });
    }
  };
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: colors.primary[800],
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
        minWidth: "400px",
        overflowY: isMobile ? "scroll" : "none",
        height: isMobile ? "80%" : "auto",
      }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <Typography variant="h4" mb={2}>
        Crear Señal
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }} >
        <InputLabel color="secondary" htmlFor="title">Titulo</InputLabel>
        <Input
          id="title"
          name="title"
          value={formValues.title}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }} >
        <InputLabel color="secondary" htmlFor="description">Descripción</InputLabel>
        <Input
          id="description"
          name="description"
          value={formValues.description}
          onChange={handleChange}
        />
      </FormControl>


      <FormControl fullWidth sx={{ mb: 2 }} >

        <Autocomplete
          fullWidth sx={{ mb: 2 }}

          id="priceID"
          options={courses}
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <TextField
              color="secondary"
              {...params}
              label="Seleccione un producto*"
              placeholder="Trading Master Course"
            />
          )}
          onChange={(event, newValue) =>
            setFormValues((prevFormValues) => ({
              ...prevFormValues,
              courseId: newValue.id
            }))
          }

          style={{ width: '100%' }}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }} >
        <Button
          variant="contained"
          color="primary"
          startIcon={<UploadFile />}
          style={{
            backgroundColor: theme.palette.secondary,
            color: theme.palette.primary.contrastText,
          }}
          component="label"
        >
          Sube un video o imagen
          <input
            type="file"
            hidden
            onChange={(event) => {
              setFormValues((prevFormValues) => ({
                ...prevFormValues,
                video: event.target.files[0],
              }));

            }}
          />
        </Button>
      </FormControl>



      <Box display="flex" justifyContent="space-around" mt={3}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCreateSignal}
        >
          Guardar
        </Button>
        <Button variant="contained" onClick={handleCloseModal}>
          Cerrar
        </Button>
      </Box>
    </Box>
  );
};

export default ModalCreateSenal;
