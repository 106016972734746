import { Box, Typography, useTheme, IconButton, Stack } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";

import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalUser from "../../components/user/ModalUser";
import Modal from "@mui/material/Modal";
import { API_URL } from "../../data/AppConstants";
import { useAuth } from "../../context/AuthContext";
import AlertDialog from "../../components/ChooseDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import LockResetIcon from "@mui/icons-material/LockReset";
import ModalChangePass from "../../components/user/ModalChangePass";
const Users = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [usuarios, setUsuarios] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("create");
  const [initialData, setInitialData] = useState(null);
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const { isMobile } = useAuth();
  const [confirmDial, setConfirmDial] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });

  const [userToChange, setUserToChange] = useState(0);
  const [openModalChange, setOpenModalChange] = useState(false);
  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleCloseToast = () => {
    setState({ ...state, open: false });
  };

  const handleOpenModal = (mode, data = null) => {
    setModalMode(mode);
    setInitialData(data);
    setIsModalOpen(true);
  };

  const handleChangePass = (id) => {
    setUserToChange(id);
    setOpenModalChange(true);
  };

  const fetchUsersData = () => {
    fetch(`${API_URL}user/users`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.ok) {
          setUsuarios(data.users);
        } else {
          setUsuarios([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = () => {
    setConfirmDial(false);
    fetch(`${API_URL}user/delete/${idToDelete}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          showToast("Usuario eliminado exitosamente!", "success");
          fetchUsersData();
        } else {
          showToast("Error en el servidor, intentelo más tarde", "error");
        }
      })
      .catch((err) => {
        showToast("Error en el servidor, intentelo más tarde", "error");
        console.log(err);
      });
  };
  const handleCloseDial = () => {
    setConfirmDial(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchUsersData();
  };

  const handleCloseModalChange = () => {
    setOpenModalChange(false);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: isMobile ? 1 : "none" },
    {
      field: "name",
      headerName: "Nombre",
      flex: isMobile ? "none" : 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row }) => {
        return <Typography>{`${row.name} ${row.lastname}`}</Typography>;
      },
    },
    {
      field: "mail",
      headerName: "Correo",
      flex: isMobile ? "none" : 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "username",
      headerName: "Usuario",
      flex: isMobile ? "none" : 1,
    },
    {
      field: "stripe_customer_id",
      headerName: "Stripe customer ID",
      flex: isMobile ? "none" : 1,
    },

    {
      field: "actions",
      headerName: "Acciones",
      width: 160,
      renderCell: ({ row }) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton
              onClick={() => handleOpenModal("edit", row)}
              color="secondary"
              aria-label="Editar"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {

                setIdToDelete(row.id);
                setConfirmDial(true);
              }}
              style={{ color: 'red' }}
              aria-label="Eliminar"
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={() => handleChangePass(row.id)}
              color="secondary"
              aria-label="Cambiar contraseña"
            >
              <LockResetIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    fetchUsersData();
  }, []);

  return (
    <Box m="20px">
      <Header
        title="Usuarios"
        subtitle="Gestiona los usuarios"
        showButton={true}
        handleOnClick={() => handleOpenModal("create")}
      />
      {
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <div>
            <ModalUser
              handleCloseModal={handleCloseModal}
              mode={modalMode}
              initialData={initialData}
            />
          </div>
        </Modal>
      }
      <Modal open={openModalChange} onClose={handleCloseModalChange}>
        <div>
          <ModalChangePass
            handleCloseModal={handleCloseModalChange}
            id={userToChange}
          />
        </div>
      </Modal>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity={state.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <AlertDialog
        open={confirmDial}
        handleClose={handleCloseDial}
        title={"¿Está seguro de borrar este usuario?"}
        content={
          "Se borraran todos los datos asociados a el y no podra recuperarlos"
        }
        handleConfirm={handleDelete}
      />
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: `${colors.primary[900]} !important`,
            fontWeight: 700,
            fontSize: "14px",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiTablePagination-toolbar": {
            color: "#000 !important",
          },
          "& .MuiDataGrid-selectedRowCount": {
            color: "#000 !important",
          },
        }}
      >
        <DataGrid
          rows={usuarios}
          columns={columns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          components={{
            NoResultsOverlay: () => (
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center"
                zIndex="999"
                position="relative"
              >
                No hay resultados para el filtro aplicado
              </Stack>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default Users;
