import React, { useState, useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { API_URL } from "../../data/AppConstants";
import Snackbar from "@mui/material/Snackbar";
import { useNavigate } from "react-router-dom";

import {
  TextField,
  Button,
  Typography,
  IconButton,
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  FormControlLabel,
  useTheme,
  CircularProgress,
  Alert,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Autocomplete,
} from "@mui/material";
import { Add, Delete, ExpandMore } from "@mui/icons-material";
import { Reorder } from "framer-motion";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { tokens } from "../../theme";
import axios from "axios";
import Loader from "../../components/Loader";

const CreateCourseForm = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [active, setActive] = useState(0);
  const [videoTypes, setvideoTypes] = useState([]);
  const [isPaid, setIsPaid] = useState(false);
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });
  const fetchVideoTypes = () => {
    fetch(`${API_URL}course/videoTypes`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          setvideoTypes(data.types);
          console.log(videoTypes);
        } else {
          setvideoTypes([]);
        }
      })
      .catch((err) => {
        setvideoTypes([]);
        console.log(err);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when fetch completes
      });
  };
  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const { control, register, handleSubmit, setValue } = useForm({
    defaultValues: {
      title: "",
      includedItems: "",
      mainImage: null,
      learnings: "",
      isActive: false,
      isFeatured: false,
      isPaid: false,
      categoryId: "",
      productId: "",
      lessons: [
        {
          title: "",
          image: null,
          description: "",
          video: null,
          videoString: "",
        },
      ],
    },
  });

  const { fields, append, remove, move, update, swap } = useFieldArray({
    control,
    name: "lessons",
  });

  useEffect(() => {
    const fetchCategoriesAndProducts = async () => {
      try {
        const categoriesResponse = await axios.get(
          `${API_URL}course/categories`
        );
        const productsResponse = await axios.get(`${API_URL}course/products`);

        setCategories(categoriesResponse.data.categories);
        setProducts(productsResponse.data.products);
      } catch (error) {
        console.error("Error fetching data:", error);
        showToast("Error al obtener las categorias", "error");
      }
    };

    fetchCategoriesAndProducts();
    fetchVideoTypes();
  }, []);

  const onSubmit = async (data) => {
    console.log(data);
    try {
      setLoading(true);

      if (!data.title) {
        showToast("El título es obligatorio.", "error");
        setLoading(false);
        return;
      }

      if (!data.mainImage || data.mainImage.length === 0) {
        showToast("La imagen principal es obligatoria.", "error");
        setLoading(false);
        return;
      }

      if (!data.lessons || data.lessons.length === 0) {
        showToast("Debe agregar al menos una lección.", "error");
        setLoading(false);
        return;
      }
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("includedItems", data.includedItems);
      formData.append("mainImage", data.mainImage[0]);
      formData.append("learnings", data.learnings);
      formData.append("isActive", data.isActive ? 1 : 0);
      formData.append("isFeatured", data.isFeatured ? 1 : 0);
      formData.append("isPaid", data.isPaid ? 1 : 0);
      formData.append("categoryId", data.categoryId);
      formData.append("productId", data.productId);

      formData.append("lessons", JSON.stringify(data.lessons));

      data.lessons.forEach((lesson, index) => {
        if (lesson.image && lesson.image.length > 0) {
          formData.append(`lessonImage_${index}`, lesson.image[0]);
        }
        if (lesson.videoString && lesson.videoString !== "") {
          formData.append(`lessonVideoString_${index}`, lesson.videoString);

        } else {
          if (lesson.video && lesson.video.length > 0) {
            formData.append(`lessonVideo_${index}`, lesson.video[0]);
          }
        }
      });
      console.log(formData);

      const response = await axios.post(`${API_URL}course/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        showToast("Curso creado correctamente!", "success");

        setTimeout(() => {
          navigate("/courses");
        }, 1000);
      }
    } catch (error) {
      showToast("Error al crear el curso, intentelo de nuevo.", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && loading2 && <Loader />}

      <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 20 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <Typography variant="h4" gutterBottom>
            Crear Curso
          </Typography>

          <FormControlLabel
            control={
              <Controller
                name="isActive"
                control={control}
                render={({ field }) => (
                  <Switch
                    {...field}
                    sx={{
                      "& .MuiSwitch-track": {
                        backgroundColor: field.value
                          ? colors.grey[100] + " !important"
                          : "grey.500",
                      },
                      "& .MuiSwitch-thumb": {
                        backgroundColor: field.value
                          ? colors.greenAccent[500]
                          : "grey.500",
                      },
                    }}
                  />
                )}
              />
            }
            label="Activo"
          />
          <FormControlLabel
            control={
              <Controller
                name="isFeatured"
                control={control}
                render={({ field }) => (
                  <Switch
                    {...field}
                    sx={{
                      "& .MuiSwitch-track": {
                        backgroundColor: field.value
                          ? colors.grey[100] + " !important"
                          : "grey.500",
                      },
                      "& .MuiSwitch-thumb": {
                        backgroundColor: field.value
                          ? colors.greenAccent[500]
                          : "grey.500",
                      },
                    }}
                  />
                )}
              />
            }
            label="Destacado"
          />
          <FormControlLabel
            control={
              <Controller
                name="isPaid"
                control={control}
                render={({ field }) => (
                  <Switch
                    {...field}
                    sx={{
                      "& .MuiSwitch-track": {
                        backgroundColor: field.value
                          ? colors.grey[100] + " !important"
                          : "grey.500",
                      },
                      "& .MuiSwitch-thumb": {
                        backgroundColor: field.value
                          ? colors.greenAccent[500]
                          : "grey.500",
                      },
                    }}
                    onChange={(e) => {
                      setValue("isPaid", e.target.checked);
                      setIsPaid(e.target.checked);
                    }}
                  />
                )}
              />
            }
            label="De pago"
          />
        </Box>

        <TextField
          color="secondary"
          label="Título del Curso"
          {...register("title")}
          fullWidth
          sx={{ mb: 3 }}
        />

        <TextField
          color="secondary"
          label="Imagen Principal"
          type="file"
          {...register("mainImage")}
          fullWidth
          InputLabelProps={{ shrink: true }}
          sx={{ mb: 3 }}
        />

        <Typography variant="h4" gutterBottom>
          Lo que incluye
        </Typography>
        <Controller
          control={control}
          name="includedItems"
          render={({ field }) => (
            <ReactQuill
              value={field.value || ""}
              onChange={field.onChange}
              modules={modules}
              formats={formats}
              className="custom-quill"
            />
          )}
        />

        <Typography variant="h4" gutterBottom sx={{ mt: 3 }}>
          Lo que se aprenderá
        </Typography>
        <Controller
          control={control}
          name="learnings"
          render={({ field }) => (
            <ReactQuill
              value={field.value || ""}
              onChange={field.onChange}
              modules={modules}
              formats={formats}
              className="custom-quill"
            />
          )}
        />

        <FormControl fullWidth sx={{ mt: 4 }}>
          <InputLabel color="secondary" id="category-label">Categoría</InputLabel>
          <Controller
            name="categoryId"
            control={control}
            render={({ field }) => (
              <Select
                color="secondary"

                labelId="category-label"
                label="Categoría"
                {...field}
                value={field.value || ""}
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
        {isPaid && (
          <>
            <FormControl fullWidth sx={{ mt: 3, mb: 3 }}>
              <InputLabel color="secondary" id="product-label">Producto</InputLabel>
              <Controller
                name="productId"
                control={control}
                render={({ field }) => (
                  <Select
                    color="secondary"

                    labelId="product-label"
                    label="Producto"
                    {...field}
                    value={field.value || ""}
                  >
                    {products.map((product) => (
                      <MenuItem key={product.id} value={product.id}>
                        {product.name} - {product.amount} € /{" "}
                        {product.recurring}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </>
        )}
        <Typography variant="h4" gutterBottom>
          Lecciones
        </Typography>

        <Reorder.Group
          values={fields}
          onReorder={(e) => {
            e.forEach((item, index) => {
              const activeElement = fields[active];
              if (item === activeElement) {
                move(active, index);
                setActive(index);
              }
            });
          }}
          style={{
            border: "2px dotted #ccc",
            padding: 8,
            borderRadius: 4,
            minHeight: "100px",
            listStyle: "none",
          }}
        >
          {fields.map((item, index) => (
            <Reorder.Item
              key={item.id}
              value={item}
              onDragStart={() => setActive(index)}
            >
              <Accordion
                key={item.id}
                style={{
                  marginBottom: "10px",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  style={{ padding: "0 16px" }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Typography variant="h5">
                      {index + 1}. {item.title || `Lección sin título`}
                    </Typography>
                    <IconButton
                      onClick={() => remove(index)}
                      disabled={fields.length <= 1}
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Controller
                    control={control}
                    name={`lessons.${index}.title`}
                    render={({ field }) => (
                      <TextField
                        color="secondary"
                        label="Título de la lección"
                        {...field}
                        fullWidth
                        margin="normal"
                      />
                    )}
                  />

                  <Typography variant="h6" gutterBottom>
                    Descripción
                  </Typography>

                  <Controller
                    control={control}
                    name={`lessons.${index}.description`}
                    render={({ field }) => (
                      <ReactQuill
                        value={field.value || ""}
                        onChange={field.onChange}
                        modules={modules}
                        formats={formats}
                        className="custom-quill"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`lessons.${index}.videoString`}
                    render={({ field }) => (
                      <TextField
                        color="secondary"
                        label="URL del video (Vimeo, Youtube, Servidor Propio)"
                        {...field}
                        fullWidth
                        margin="normal"
                        value={field.value || ""}
                      />
                    )}
                  />

                  <TextField
                    color="secondary"
                    label="Video (si desea proporcionar el archivo)"
                    type="file"
                    {...register(`lessons.${index}.video`)}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                  />

                  <TextField
                    color="secondary"
                    label="Imagen"
                    type="file"
                    {...register(`lessons.${index}.image`)}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                  />
                </AccordionDetails>
              </Accordion>
            </Reorder.Item>
          ))}
        </Reorder.Group>

        <Grid
          container
          justifyContent="flex-end"
          spacing={2}
          style={{ marginTop: 8 }}
        >
          <Grid item>
            <Button
              type="button"
              variant="contained"
              sx={{
                backgroundColor: colors.greenAccent[500],
                color: "#000",
                fontWeight: "700",
                "&:hover": {
                  backgroundColor: colors.greenAccent[300],
                  color: "#222",
                },
              }}
              onClick={() =>
                append({
                  title: "",
                  description: "",
                  videoString: "",
                  video: null,
                  image: null,
                })
              }
            >
              <Add /> Añadir Lección
            </Button>
          </Grid>
        </Grid>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          autoHideDuration={3000}
          open={state.open}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={state.type}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {state.message}
          </Alert>
        </Snackbar>

        <Grid
          container
          justifyContent="center"
          spacing={2}
          style={{ marginTop: 16 }}
        >
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: colors.greenAccent[500],
                color: "#000",
                fontWeight: "700",
                "&:hover": {
                  backgroundColor: colors.greenAccent[300],
                  color: "#222",
                },
              }}
            >
              Guardar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: colors.primary[400],
                color: "#fff",
                fontWeight: "700",
              }}
              onClick={() => navigate("/courses")}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CreateCourseForm;
