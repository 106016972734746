import { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  Typography,
  Box,
  useTheme,
  FormControl,
  InputLabel,
  Input,
  Button,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Checkbox,
  Chip,
} from "@mui/material";
import { API_URL } from "../../data/AppConstants";
import { tokens } from "../../theme";
import { useAuth } from "../../context/AuthContext";
import dayjs from "dayjs";
import esLocale from "dayjs/locale/es";
dayjs.locale(esLocale);

const ModalCreateCoupon = ({ handleCloseModal }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { isMobile } = useAuth();
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
    usersForDropDown: [],
    productsForDropDown: [],
  });

  const fetchUsers = async (search = "notGiven") => {
    try {
      const response = await fetch(`${API_URL}user/users`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      let processedData = data.users.map((item) => ({
        id: item.id,
        email: item.mail,
      }));
      setState((prevState) => ({
        ...prevState,
        usersForDropDown: processedData,
      }));
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchProducts = async (search = "notGiven") => {
    try {
      const response = await fetch(`${API_URL}course/products`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log(data.products);
      let processedData = data.products.map((item) => ({
        id: item.id,
        product: item.name,
        priceId: item.stripe_price_id,
      }));
      setState((prevState) => ({
        ...prevState,
        productsForDropDown: processedData,
      }));
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchProducts();
  }, []);

  const showToast = (message, type) => {
    setState({ ...state, open: true, message, type });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const [formValues, setFormValues] = useState({
    code: "",
    discount: "",
    discountType: "percentage",
    discountType: "percentage",
    users: [],
    priceID: "",
  });

  const handleChange = (event, newValue) => {
    const { name, value, type, checked } = event.target || {};

    // Handling standard input changes
    if (name) {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: type === "checkbox" ? checked : value,
      }));
    }

    if (name === "users") {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        users: newValue,
      }));
    }

    if (name === "priceID") {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        priceID: newValue.priceId,
      }));
    }
  };

  const handleCreateCoupon = () => {
    if (formValues.code === "" || formValues.discount === "") {
      showToast("Por favor, complete todos los campos.", "error");
    } else {
      console.log(formValues);
      let userIdList = formValues.users.map((item) => item.id);
      fetch(`${API_URL}panel/cupon`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: formValues.code,
          discount: formValues.discount,
          priceID: formValues.priceID,
          discountType: formValues.discountType,
          users: userIdList,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            showToast(data.message, "success");
            setTimeout(() => {
              handleCloseModal();
            }, 1000);
          } else {
            showToast(data.error, "error");
          }
        })
        .catch((err) => {
          console.log(err);
          showToast("Error en el servidor, intentelo más tarde", "error");
        });
    }
  };
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: colors.primary[800],
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
        minWidth: "400px",
        overflowY: isMobile ? "scroll" : "none",
        height: isMobile ? "80%" : "auto",
      }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <Typography variant="h4" mb={2}>
        Crear Cupon
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel color="secondary" htmlFor="code">
          Codigo del cupon
        </InputLabel>
        <Input
          id="code"
          name="code"
          value={formValues.code}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel color="secondary" id="discountType-label">
          Tipo de descuento
        </InputLabel>
        <Select
          color="secondary"
          labelId="discountType-label"
          id="discountType"
          name="discountType"
          defaultValue="percentage"
          onChange={handleChange}
          label="Tipo de descuento"
        >
          <MenuItem key={1} value="percentage">
            %
          </MenuItem>
          <MenuItem key={2} value="currency">
            €
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel color="secondary" htmlFor="discount">
          Descuento {formValues.discountType === "percentage" ? "0-100" : ""}
        </InputLabel>
        <Input
          id="discount"
          name="discount"
          value={formValues.discount}
          onChange={handleChange}
        />
      </FormControl>

      <FormControl fullWidth sx={{ mb: 2 }}>
        <Autocomplete
          multiple
          id="users"
          options={state.usersForDropDown}
          disableCloseOnSelect
          getOptionLabel={(option) => option.email}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox checked={selected} style={{ marginRight: 8 }} />
              {option.email}
            </li>
          )}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              color="secondary"
              {...params}
              label="Seleccione sus usuarios"
              placeholder="ejemplo@ejemplo.com"
            />
          )}
          onChange={(event, newValue) =>
            handleChange({ target: { name: "users" } }, newValue)
          }
          renderTags={(selected, getTagProps) =>
            selected.map((option, index) => (
              <Chip
                key={index}
                label={option.email}
                {...getTagProps({ index })}
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }}
              />
            ))
          }
        />
      </FormControl>

      <FormControl fullWidth sx={{ mb: 2 }}>
        <Autocomplete
          fullWidth
          sx={{ mb: 2 }}
          id="priceID"
          options={state.productsForDropDown}
          getOptionLabel={(option) => option.product}
          renderInput={(params) => (
            <TextField
              color="secondary"
              {...params}
              label="Seleccione un producto*"
              placeholder="Trading Master Course"
            />
          )}
          onChange={(event, newValue) =>
            handleChange({ target: { name: "priceID" } }, newValue)
          }
          style={{ width: "100%" }}
        />
      </FormControl>

      <Box display="flex" justifyContent="space-around" mt={3}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCreateCoupon}
        >
          Guardar
        </Button>
        <Button variant="contained" onClick={handleCloseModal}>
          Cerrar
        </Button>
      </Box>
    </Box>
  );
};

export default ModalCreateCoupon;
