import { Box, Typography, useTheme, IconButton, Stack } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "@mui/material/Modal";
import { API_URL } from "../../data/AppConstants";
import { useAuth } from "../../context/AuthContext";
import AlertDialog from "../../components/ChooseDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Edit, PersonSearch } from "@mui/icons-material";
import ModalCreateSenal from "../../components/senales/ModalCreateSenal";
import ModalEditSenal from "../../components/senales/ModalEditSenal";
import { set } from "date-fns";

const Senales = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [senales, setsenales] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [EditSignalModal, setEditSignalModal] = useState({
    state: false,
    signal: {},
  });
  const { isMobile } = useAuth();
  const [courses, setCourses] = useState([]);
  const [confirmDial, setConfirmDial] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });

  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleCloseToast = () => {
    setState({ ...state, open: false });
  };

  const fetchCursos = () => {
    fetch(`${API_URL}course/coursesNoLessons`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.ok) {
          setCourses(data.courses);
          // Update senales with course_name
          setsenales((prevSenales) =>
            prevSenales.map((senal) => {
              const matchedCourse = data.courses.find(
                (course) => course.id === senal.course_id
              );
              return {
                ...senal,
                course_name: matchedCourse ? matchedCourse.title : "Unknown",
              };
            })
          );
        } else {
          setCourses([]);
        }
      })
      .catch((err) => {
        setsenales([]);
        console.log(err);
      });
  };

  const fetchSenales = () => {
    fetch(`${API_URL}admin/senales`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.ok) {
          setsenales(data.senales);
        } else {
          setsenales([]);
        }
      })
      .catch((err) => {
        setsenales([]);
        console.log(err);
      });
  };

  const handleDelete = () => {
    setConfirmDial(false);
    fetch(`${API_URL}admin/senal/${idToDelete}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          showToast("Señal eliminada exitosamente!", "success");
          fetchSenales();
        } else {
          showToast("Error en el servidor, intentelo más tarde", "error");
        }
      })
      .catch((err) => {
        showToast("Error en el servidor, intentelo más tarde", "error");
        console.log(err);
      });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditSignalModal({ state: false, signal: {} });
    fetchSenales();
    fetchCursos();
  };

  const handleCloseDial = () => {
    setConfirmDial(false);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: isMobile ? 1 : "none" },
    {
      field: "title",
      headerName: "Titulo",
      flex: isMobile ? "none" : 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "course_name",
      headerName: "Curso",
      flex: isMobile ? "none" : 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "description",
      headerName: "Descripción",
      flex: isMobile ? "none" : 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "video",
      headerName: "Media",
      flex: isMobile ? "none" : 1,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => {
        return <Typography>{row.video}</Typography>;
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 160,
      renderCell: ({ row }) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            {/* <IconButton
              onClick={() => {
                setEditSignalModal({ state: true, signal: row });
              }}
              color="secondary"
              aria-label="Ver"
            >
              <Edit />
            </IconButton> */}
            <IconButton
              onClick={() => {
                setIdToDelete(row.id);
                setConfirmDial(true);
              }}
              sx={{ color: "red" }}
              aria-label="Eliminar"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    fetchSenales();
    fetchCursos();
  }, []);

  return (
    <Box m="20px">
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity={state.type}
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <Header
        title="Señales"
        subtitle="Gestiona las señales"
        showButton={true}
        handleOnClick={handleOpenModal}
      />
      {
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <div>
            <ModalCreateSenal
              handleCloseModal={handleCloseModal}
              courses={courses}
            />
          </div>
        </Modal>
      }
      {/* {
                <Modal open={EditSignalModal.state} onClose={handleCloseModal}>
                    <div>
                        <ModalEditSenal handleCloseModal={handleCloseModal} signal={EditSignalModal.signal} courses={courses} />
                    </div>
                </Modal>
            } */}

      <AlertDialog
        open={confirmDial}
        handleClose={handleCloseDial}
        title={"¿Está seguro de borrar esta señal?"}
        content={"No se podra recuperar"}
        handleConfirm={handleDelete}
      />
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: `${colors.primary[900]} !important`,
            fontWeight: 700,
            fontSize: "14px",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiTablePagination-toolbar": {
            color: "#000 !important",
          },
          "& .MuiDataGrid-selectedRowCount": {
            color: "#000 !important",
          },
        }}
      >
        <DataGrid
          rows={senales}
          columns={columns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          components={{
            NoResultsOverlay: () => (
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center"
                zIndex="999"
                position="relative"
              >
                No hay resultados para el filtro aplicado
              </Stack>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default Senales;
