import React, { useState, useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { API_URL } from "../../data/AppConstants";
import Snackbar from "@mui/material/Snackbar";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  IconButton,
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  FormControlLabel,
  CircularProgress,
  Alert,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  useTheme,
} from "@mui/material";
import { Add, ArrowBack, Delete, ExpandMore } from "@mui/icons-material";
import { Reorder } from "framer-motion";
import ReactQuill, { Quill } from "react-quill";

import "react-quill/dist/quill.snow.css";
import axios from "axios";
import Loader from "../../components/Loader";
import { tokens } from "../../theme";

const EditCourseForm = ({ course, handleCloseForm }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [isPaid, setIsPaid] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const { control, register, handleSubmit, setValue } = useForm({
    defaultValues: {
      title: "",
      includedItems: "",
      mainImage: "",
      learnings: "",
      isActive: course?.isActive === 1 ? true : false,
      isFeatured: course?.isFeatured === 1 ? true : false,
      isPaid: course?.isPaid === 1 ? true : false,
      categoryId: "",
      productId: "",
      lessons: [
        {
          title: "",
          image: null,
          description: "",
          video: null,
          videoString: "",
        },
      ],
    },
  });

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "lessons",
  });

  useEffect(() => {
    const fetchCategoriesAndProducts = async () => {
      try {
        const categoriesResponse = await axios.get(
          `${API_URL}course/categories`
        );
        const productsResponse = await axios.get(`${API_URL}course/products`);
        setCategories(categoriesResponse.data.categories);
        setProducts(productsResponse.data.products);
      } catch (error) {
        showNotification(
          "Error al obtener las categorías y productos.",
          "error"
        );
      }
    };
    setValue("title", course?.title || "");
    setValue("categoryId", course?.categoryId || "");
    setValue("includedItems", course?.includedItems || "");

    setValue("learnings", course?.learnings || "");
    setValue("mainImage", course?.mainImage || "");
    setValue("lessons", course?.lessons || []);

    fetchCategoriesAndProducts();
  }, [course, setValue]);
  const htmlToDelta = (html) => {
    const quill = new Quill(document.createElement("div"));
    quill.clipboard.dangerouslyPasteHTML(html);
    return quill.getContents();
  };
  const showNotification = (message, type) => {
    setNotification({ open: true, message, type });
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const onSubmit = async (data) => {
    if (!data.title || !data.mainImage || data.lessons.length === 0) {
      showNotification(
        "Todos los campos obligatorios deben ser completados.",
        "error"
      );
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("courseId", course.id);
      formData.append("title", data.title);
      formData.append("includedItems", data.includedItems);
      formData.append("mainImage", data.mainImage[0]);
      formData.append("learnings", data.learnings);
      formData.append("isActive", data.isActive ? 1 : 0);
      formData.append("isFeatured", data.isFeatured ? 1 : 0);
      formData.append("isPaid", data.isPaid ? 1 : 0);
      formData.append("categoryId", data.categoryId);
      formData.append("productId", data.productId || course.productId);
      formData.append("lessons", JSON.stringify(data.lessons));

      data.lessons.forEach((lesson, index) => {
        if (lesson.image)
          formData.append(`lessonImage_${index}`, lesson.image[0]);
        if (lesson.videoString)
          formData.append(`lessonVideoString_${index}`, lesson.videoString);
        if (lesson.video)
          formData.append(`lessonVideo_${index}`, lesson.video[0]);
      });
   

      const response = await axios.put(
        `${API_URL}course/course/${course.id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.status === 200) {
        showNotification("Curso actualizado correctamente!", "success");
       handleCloseForm();
      } else {
        showNotification(`Error: ${response.data.error}`, "error");
      }
    } catch (error) {
      showNotification(
        `Error al actualizar el curso: ${error.message}`,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}

      <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 20 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <Typography variant="h4" gutterBottom>
            Editar Curso
          </Typography>
          <FormControlLabel
            control={
              <Controller
                name="isActive"
                control={control}
                render={({ field }) => (
                  <Switch
                    {...field}
                    checked={field.value}
                    sx={{
                      "& .MuiSwitch-track": {
                        backgroundColor: field.value
                          ? colors.grey[100] + " !important"
                          : "grey.500",
                      },
                      "& .MuiSwitch-thumb": {
                        backgroundColor: field.value
                          ? colors.greenAccent[500]
                          : "grey.500",
                      },
                    }}
                  />
                )}
              />
            }
            label="Activo"
          />
          <FormControlLabel
            control={
              <Controller
                name="isFeatured"
                control={control}
                render={({ field }) => (
                  <Switch
                    {...field}
                    checked={field.value}
                    sx={{
                      "& .MuiSwitch-track": {
                        backgroundColor: field.value
                          ? colors.grey[100] + " !important"
                          : "grey.500",
                      },
                      "& .MuiSwitch-thumb": {
                        backgroundColor: field.value
                          ? colors.greenAccent[500]
                          : "grey.500",
                      },
                    }}
                  />
                )}
              />
            }
            label="Destacado"
          />
          <FormControlLabel
            control={
              <Controller
                name="isPaid"
                control={control}
                render={({ field }) => (
                  <Switch
                    {...field}
                    checked={field.value}
                    sx={{
                      "& .MuiSwitch-track": {
                        backgroundColor: field.value
                          ? colors.grey[100] + " !important"
                          : "grey.500",
                      },
                      "& .MuiSwitch-thumb": {
                        backgroundColor: field.value
                          ? colors.greenAccent[500]
                          : "grey.500",
                      },
                    }}
                    onChange={(e) => {
                      setValue("isPaid", e.target.checked);
                      setIsPaid(e.target.checked);
                    }}
                  />
                )}
              />
            }
            label="De pago"
          />
        </Box>

        <TextField
          label="Título del Curso"
          {...register("title")}
          fullWidth
          sx={{ mb: 3 }}
        />
        {course?.mainImage && (
          <Box sx={{ mb: 2 }}>
            <img
              src={course.mainImage}
              alt="Imagen Actual"
              style={{ width: "100%", maxHeight: "200px", objectFit: "cover" }}
            />
          </Box>
        )}
        <TextField
          label="Imagen Principal"
          type="file"
          {...register("mainImage")}
          fullWidth
          InputLabelProps={{ shrink: true }}
          sx={{ mb: 3 }}
        />

        <Typography variant="h4" gutterBottom>
          Lo que incluye
        </Typography>
        <Controller
          control={control}
          name="includedItems"
          render={({ field }) => (
            <ReactQuill
              value={field.value}
              onChange={field.onChange}
              modules={modules}
              formats={formats}
            />
          )}
        />

        <Typography variant="h4" gutterBottom sx={{ mt: 3 }}>
          Lo que se aprenderá
        </Typography>
        <Controller
          control={control}
          name="learnings"
          render={({ field }) => (
            <ReactQuill
              value={field.value}
              onChange={field.onChange}
              modules={modules}
              formats={formats}
            />
          )}
        />

        <FormControl fullWidth sx={{ mt: 4 }}>
          <InputLabel id="category-label">Categoría</InputLabel>
          <Controller
            name="categoryId"
            control={control}
            render={({ field }) => (
              <Select
                labelId="category-label"
                label="Categoría"
                {...field}
                value={field.value || ""}
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
        {isPaid && (
          <FormControl fullWidth sx={{ mt: 3, mb: 3 }}>
            <InputLabel id="product-label">Producto</InputLabel>
            <Controller
              name="productId"
              control={control}
              render={({ field }) => (
                <Select
                  labelId="product-label"
                  label="Producto"
                  {...field}
                  value={field.value || ""}
                >
                  {products.map((product) => (
                    <MenuItem key={product.id} value={product.id}>
                      {product.name} - {product.amount} € / {product.recurring}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        )}

        <Typography variant="h4" gutterBottom>
          Lecciones
        </Typography>
        <Reorder.Group
          axis="y"
          onReorder={move}
          values={fields}
          as="div"
          style={{ marginBottom: "16px" }}
        >
          {fields.map((item, index) => (
            <Accordion key={item.id} sx={{ mb: 2 }}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Lección {index + 1}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <IconButton onClick={() => remove(index)} color="error">
                    <Delete />
                  </IconButton>
                </Box>
                <TextField
                  label="Título"
                  {...register(`lessons.${index}.title`)}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                  {item?.image && item?.image !== '' ? (
                  <img width="560" height="315" src={item.image} />
                ) : null}

                <TextField
                  label="Imagen"
                  type="file"
                   color="secondary"
                  {...register(`lessons.${index}.image`)}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  sx={{ mb: 2 }}
                />
                <Controller
                  color="secondary"
                  control={control}
                  name={`lessons.${index}.description`}
                  render={({ field }) => (
                    <ReactQuill
                      value={field.value}
                      onChange={field.onChange}
                      modules={modules}
                      formats={formats}
                      className="custom-quill"
                    />
                  )}
                />

             
                <TextField
                color="secondary"
                  label="Video (URL o archivo)"
                  value={item.video}
                  {...register(`lessons.${index}.videoString`)}
                  fullWidth
                  sx={{ mb: 2, mt: 2 }}
                />
                <TextField
                   color="secondary"
                  label="Video (archivo)"
                  type="file"
                  {...register(`lessons.${index}.video`)}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </AccordionDetails>
            </Accordion>
          ))}
        </Reorder.Group>

        <Grid
          container
          justifyContent="flex-end"
          spacing={2}
          style={{ marginTop: 8 }}
        >
          <Grid item>
            <Button
              type="button"
              variant="contained"
              sx={{
                backgroundColor: colors.greenAccent[500],
                color: "#000",
                fontWeight: "700",
                "&:hover": {
                  backgroundColor: colors.greenAccent[300],
                  color: "#222",
                },
              }}
              onClick={() =>
                append({
                  title: "",
                  description: "",
                  videoString: "",
                  video: null,
                  image: null,
                })
              }
            >
              <Add /> Añadir Lección
            </Button>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
          <Button type="button" variant="outlined" onClick={handleCloseForm}>
            Cancelar
          </Button>
          <Button type="submit" variant="contained">
            Guardar
          </Button>
        </Box>
      </form>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
      >
        <Alert onClose={handleCloseNotification} severity={notification.type}>
          {notification.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditCourseForm;
