import { useState, useEffect } from "react";
import { Box, Typography, useTheme, Select, MenuItem } from "@mui/material";
import { tokens } from "../../theme";
import EmailIcon from "@mui/icons-material/Email";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import { API_URL } from "../../data/AppConstants";
import StatBox from "../../components/StatBox";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import esLocale from "dayjs/locale/es";
dayjs.locale(esLocale);

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [users, setUsers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [dashboardData, setDashboardData] = useState({});
  const [dateUltimoDia, setDateUltimoDia] = useState(dayjs());
  const [selectedYear, setSelectedYear] = useState("2024");
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let year = 2024; year <= currentYear; year++) {
    yearOptions.push(
      <MenuItem key={year} value={year}>
        {year}
      </MenuItem>
    );
  }
  const isMobile =
    window.innerWidth <= 600 || /Mobi|Android/i.test(navigator.userAgent);

  const fecthOrders = () => {
    fetch(`${API_URL}auth/all-orders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ month: "", year: "" }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          setOrders(data.orders.reverse());
        } else {
          setOrders([]);
        }
      })
      .catch((err) => {
        setOrders([]);
        console.log(err);
      });
  };
  const fetchUsersData = () => {
    fetch(`${API_URL}user/users`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          setUsers(data.users.reverse());
        } else {
          setUsers([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const fetchData = () => {
    fetch(`${API_URL}auth/panel/dashboard`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        dateStart: dateUltimoDia,
        dateEnd: dayjs(),
        year: selectedYear,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.ok) {
          setDashboardData(data.result);
        } else {
          setDashboardData({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchUsersData();
    // fecthOrders();
    // fetchData();
  }, [dateUltimoDia, selectedYear]);

  const cases = {
    1: "1",
    2: "2",
    3: "2",
    4: "3",
    5: "3",
    6: "4",
    7: "4",
    8: "5",
    9: "5",
    10: "5",
  };
  return (
    <Box m="20px">
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* <Box
          gridColumn={`span ${isMobile ? "12" : "3"}`}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="column"
          >
            <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
              Usuarios activos
            </Typography>
            <Typography
              color={colors.greenAccent[300]}
              variant="h3"
              fontWeight="400"
            >
              {dashboardData.usuariosActivos}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="column"
          >
            <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
              Usuarios Inactivos
            </Typography>
            <Typography
              color={colors.greenAccent[300]}
              variant="h3"
              fontWeight="400"
            >
              {dashboardData.usuariosInactivos}
            </Typography>
          </Box>
        </Box>
        <Box
          gridColumn={`span ${isMobile ? "12" : "3"}`}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="column"
          >
            <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
              Basic
            </Typography>
            <Typography
              color={colors.greenAccent[300]}
              variant="h3"
              fontWeight="400"
            >
              {dashboardData.basicUsers}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="column"
          >
            <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
              Gold
            </Typography>
            <Typography
              color={colors.greenAccent[300]}
              variant="h3"
              fontWeight="400"
            >
              {dashboardData.goldUsers}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="column"
          >
            <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
              Platinum
            </Typography>
            <Typography
              color={colors.greenAccent[300]}
              variant="h3"
              fontWeight="400"
            >
              {dashboardData.platinumUsers}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="column"
          >
            <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
              Unlimited
            </Typography>
            <Typography
              color={colors.greenAccent[300]}
              variant="h3"
              fontWeight="400"
            >
              {dashboardData.unlimitedUsers}
            </Typography>
          </Box>
        </Box>
        <Box
          gridColumn={`span ${isMobile ? "12" : "2"}`}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={dashboardData.totalAfiliados}
            subtitle="Total de afiliados"
            progress="0"
            increase={`${dashboardData.totalAfiliados} €`}
            icon={
              <CardMembershipIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          locale={esLocale}
          adapterLocale="es"
        >
          <Box
            gridColumn={`span ${isMobile ? "12" : "4"}`}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap="10px"
              colors={colors.grey[100]}
              p="15px"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexDirection="column"
              >
                <Typography
                  color={colors.grey[100]}
                  variant="h4"
                  fontWeight="600"
                >
                  Facturado ultimo dia
                </Typography>
                <Typography
                  color={colors.greenAccent[300]}
                  variant="h3"
                  fontWeight="400"
                >
                  {dashboardData.totalFacturadoUltimoDia} €
                </Typography>
                <DemoContainer components={["DatePicker"]}>
                  <DemoItem>
                    <DatePicker
                      value={dateUltimoDia}
                      onChange={(newValue) => setDateUltimoDia(newValue)}
                      format="DD/MM/YYYY"
                      localeText={{
                        clearButtonLabel: "Vaciar",
                        todayButtonLabel: "Hoy",
                        okButtonLabel: "Guardar",
                        cancelButtonLabel: "Cancelar",
                        toolbarTitle: "Selecciona fecha y hora",
                      }}
                      slotProps={{
                        color: "#fcfcfc",

                        clearIcon: {
                          style: {
                            color: "#000000",
                          },
                        },
                      }}
                    />
                  </DemoItem>
                </DemoContainer>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexDirection="column"
              >
                <Typography
                  color={colors.grey[100]}
                  variant="h4"
                  fontWeight="600"
                >
                  Facturado anual
                </Typography>
                <Typography
                  color={colors.greenAccent[300]}
                  variant="h3"
                  fontWeight="400"
                >
                  {dashboardData.totalFacturadoAno} €
                </Typography>
                <Box height="52px" paddingTop="8px" flex="1">
                   <Select
            color="secondary"
                    value={selectedYear}
                    onChange={handleYearChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Seleccione un año" }}
                    sx={{
                      height: "52px",
                      width: "110px",
                    }}
                  >
                    <MenuItem value="">Año</MenuItem>
                    {yearOptions}
                  </Select>
                </Box>
              </Box>
            </Box>
          </Box>
        </LocalizationProvider> */}
        <Box
          gridColumn={`span ${isMobile ? "12" : "6"}`}
          gridRow={`span ${isMobile ? cases[orders.length] : "5"}`}
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Ultimos Pedidos
            </Typography>
          </Box>
          {orders.map((order, i) => (
            <Box
              key={`${order.id}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box flex="1">
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {order.id}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {order.name} {order.lastname}
                </Typography>
              </Box>
              <Box flex="1" color={colors.grey[100]}>
                {order.description}
              </Box>
              <Box
                flex="1"
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
                maxWidth="20%"
                textAlign="center"
                color={colors.primary[900]}
                fontWeight={700}
              >
                € {order.amount}
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          gridColumn={`span ${isMobile ? "12" : "6"}`}
          gridRow={`span ${isMobile ? cases[users.length] : "5"}`}
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Ultimos usuarios
            </Typography>
          </Box>
          {users.map((user, i) => (
            <Box
              key={`${user.id}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box flex="1">
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {user.id}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {user.name} {user.lastname}
                </Typography>
              </Box>
              <Box flex="1" color={colors.grey[100]}>
                {user.mail}
              </Box>
              <Box
                flex="1"
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
                maxWidth="25%"
                textAlign="center"
                color={colors.primary[900]}
                fontWeight={700}
              >
                {user.username}
              </Box>
            </Box>
          ))}
        </Box>

        {/* ROW 3 */}
        {/* <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Campaign
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              $48,352 revenue generated
            </Typography>
            <Typography>Includes extra misc expenditures and costs</Typography>
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Sales Quantity
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          padding="30px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Geography Based Traffic
          </Typography>
          <Box height="200px">
            <GeographyChart isDashboard={true} />
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Dashboard;
