import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
    Typography,
    Box,
    useTheme,
    FormControl,
    InputLabel,
    Input,
    Select,
    Button,
    MenuItem,
    TextField,
    InputAdornment,
} from "@mui/material";
import { API_URL } from "../../data/AppConstants";
import { tokens } from "../../theme";
import { useAuth } from "../../context/AuthContext";

const EditProductModal = ({ handleCloseModal, product }) => {
    // useEffect(() => {
    //     console.log(getDefaultInterval(product.recurring));

    // }, []); // The empty array ensures this runs only once, after the initial render.
    function getDefaultInterval(value) {
        const interval = product.recurring;
        switch (interval) {
            case 'mes':
                return "1";
            case '3 meses':
                return "3";
            case '6 meses':
                return "6";
            case '12 meses':
                return "12";
            default:
                return "0"; // or another default value
        }
    }


    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { isMobile } = useAuth();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        defaultValues: { productName: product.name, intervalCount: getDefaultInterval(product.recurring), amount: product.amount },
    });

    const [state, setState] = useState({
        open: false,
        message: "Snack",
        type: "success",
    });


    const showToast = (message, type) => {
        setState({ open: true, message, type });
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const onSubmit = (data) => {
        data = {
            ...data,
            productId: product.id
        }
        fetch(`${API_URL}payment/price`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.ok) {
                    showToast(data.message, "success");
                    setTimeout(() => {
                        handleCloseModal();
                    }, 1000);
                } else {
                    showToast(data.message, "error");
                }
            })
            .catch((err) => {
                console.log(err);
                showToast("Error en el servidor, intentelo más tarde", "error");
            });
    };
    const handleAmountChange = (e) => {
        let value = e.target.value;

        const formattedValue = value
            .replace(/[^0-9.]/g, "")
            .replace(/(\..*)\./g, "$1");
        setValue("amount", formattedValue);
    };
    return (
        <Box
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: colors.primary[800],
                boxShadow: 24,
                p: 4,
                borderRadius: "8px",
                minWidth: "400px",
                overflowY: isMobile ? "scroll" : "none",
                height: isMobile ? "80%" : "auto",
            }}
        >
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                autoHideDuration={3000}
                open={state.open}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={state.type}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {state.message}
                </Alert>
            </Snackbar>
            <Typography variant="h4" mb={2}>
                Editar Producto
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel color="secondary" htmlFor="productName">Nombre del producto</InputLabel>
                    <Input
                        id="productName"
                        {...register("productName", { required: "El nombre es requerido" })}
                    />
                    {errors.productName && <p>{errors.productName.message}</p>}
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel color="secondary" htmlFor="intervalCount">Intervalo de tiempo</InputLabel>
                    <Select
                        color="secondary"
                        label="Intervalo de tiempo"
                        id="intervalCount"
                        defaultValue={getDefaultInterval('a')}
                        {...register("intervalCount", {
                            required: "El intervalo es requerido",
                        })}
                    >
                        <MenuItem value="0">Pago único</MenuItem>
                        <MenuItem value="1">1 mes</MenuItem>
                        <MenuItem value="3">3 meses</MenuItem>
                        <MenuItem value="6">6 meses</MenuItem>
                        <MenuItem value="12">12 meses</MenuItem>
                    </Select>
                    {errors.intervalCount && <p>{errors.intervalCount.message}</p>}
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                        color="secondary"
                        id="amount"
                        label="Cantidad"
                        type="text"
                        {...register("amount", { required: "La cantidad es requerida" })}
                        onChange={handleAmountChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">€</InputAdornment>
                            ),
                        }}
                    />
                    {errors.amount && <p>{errors.amount.message}</p>}
                </FormControl>

                <Box display="flex" justifyContent="space-around" mt={3}>
                    <Button variant="contained" color="secondary" type="submit">
                        Guardar
                    </Button>
                    <Button variant="contained" onClick={handleCloseModal}>
                        Cerrar
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default EditProductModal;
