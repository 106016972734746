import { useForm } from "react-hook-form";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  Typography,
  Box,
  useTheme,
  FormControl,
  InputLabel,
  Input,
  Button,
} from "@mui/material";
import { API_URL } from "../data/AppConstants";
import { tokens } from "../theme";
import { useAuth } from "../context/AuthContext";

const ModalCategory = ({ handleCloseModal, mode, initialData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { isMobile } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: initialData || { name: "", description: "" },
  });

  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });

  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const onSubmit = (data) => {
    const method = mode === "create" ? "POST" : "PUT";

    fetch(`${API_URL}course/category`, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          showToast(data.message, "success");
          setTimeout(() => {
            handleCloseModal();
          }, 1000);
        } else {
          showToast(data.message, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        showToast("Error en el servidor, intentelo más tarde", "error");
      });
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: colors.primary[800],
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
        minWidth: "400px",
        overflowY: isMobile ? "scroll" : "none",
        height: isMobile ? "80%" : "auto",
      }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <Typography variant="h4" mb={2}>
        {mode === "create" ? "Crear Categoria" : "Editar Categoria"}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel color="secondary" htmlFor="name">Categoria</InputLabel>
          <Input
            id="name"
            {...register("name", { required: "El nombre es requerido" })}
          />
          {errors.name && <p>{errors.name.message}</p>}
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel color="secondary" htmlFor="description">Descripcion</InputLabel>
          <Input
            id="description"
            {...register("description", {
              required: "La descripción es requerida",
            })}
          />
          {errors.description && <p>{errors.description.message}</p>}
        </FormControl>

        <Box display="flex" justifyContent="space-around" mt={3}>
          <Button variant="contained" color="secondary" type="submit">
            {mode === "create" ? "Guardar" : "Actualizar"}
          </Button>
          <Button variant="contained" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ModalCategory;
