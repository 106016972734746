import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import { useAuth } from "../../context/AuthContext";
import { API_URL } from "../../data/AppConstants";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SchoolIcon from "@mui/icons-material/School";
import CategoryIcon from "@mui/icons-material/Category";
import InventoryIcon from "@mui/icons-material/Inventory";
import PersonIcon from "@mui/icons-material/Person";
import { LocalOffer, SwapVert, TrendingUp } from "@mui/icons-material";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selected, setSelected] = useState("Dashboard");
  const [currentYear, setCurrentYear] = useState("2023");
  const [permisosSp, setPermisos] = useState([]);
  const { isAdmin, mobileMenu, toogleMobileMenu, isMobile, loggedUserId } =
    useAuth();
  const [showIframe, setShowIframe] = useState(false);

  //const permisosSp = permisos2?.split(",");
  const toggleIframe = () => {
    setShowIframe(!showIframe);
  };
  const fetchPermisos = () => {
    fetch(`${API_URL}admin/permisos`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId: loggedUserId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          setPermisos(data.permisos.split(","));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    setCurrentYear(year);
    fetchPermisos();
  }, []);

  return (
    <>
      <Box
        sx={{
          "& .pro-sidebar-inner": {
            background: `${colors.primary[400]} !important`,
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            padding: "5px 35px 5px 20px !important",
          },
          "& .pro-inner-item:hover": {
            color: "#1ce600 !important",
          },
          "& .pro-menu-item.active": {
            color: "#05ff00 !important",
          },
        }}
        style={{
          position: "relative",
          height: "100%",
          display: isMobile && mobileMenu ? "none" : "block",
        }}
      >
        <ProSidebar collapsed={isCollapsed}>
          <Menu iconShape="square">
            <MenuItem
              onClick={() =>
                isMobile ? toogleMobileMenu(true) : setIsCollapsed(!isCollapsed)
              }
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: "10px 0 20px 0",
                color: colors.grey[100],
              }}
            >
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="15px"
                >
                  {/* <Typography variant="h3" color={colors.grey[100]}>
                  CUVO
                </Typography> */}
                  <Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <img
                        alt="profile-user"
                        width="140px"
                        height="auto"
                        src={`../../assets/logo.svg`}
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                  </Box>
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon />
                  </IconButton>
                </Box>
              )}
            </MenuItem>

            <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              {isAdmin && (
                <>
                  {permisosSp?.includes("Panel de control") && (
                    <Item
                      title="Panel de control"
                      to="/dashboard"
                      icon={<HomeOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                  <Item
                    title="Usuarios"
                    to="/users"
                    icon={<PersonIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Categorias"
                    to="/categories"
                    icon={<CategoryIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Productos"
                    to="/products"
                    icon={<InventoryIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Cursos"
                    to="/courses"
                    icon={<SchoolIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Cupones"
                    to="/Coupons"
                    icon={<LocalOffer />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Señales"
                    to="/senales"
                    icon={<TrendingUp />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </>
              )}
            </Box>
            <MenuItem
              style={{
                margin: "10px 0 20px 0",
                color: colors.grey[100],
              }}
            >
              {!isCollapsed && (
                <Box
                  style={{
                    paddingLeft: 0,
                    margin: "0px auto",
                  }}
                >
                  <Typography
                    variant="h5"
                    color={colors.grey[200]}
                    textAlign="center"
                    paddingBottom="20px"
                  >
                    © {currentYear} EmprendeYa.es. <br /> Todos los derechos
                    reservados. <br /> App desarrollada <br /> por{" "}
                    <span>
                      <strong>
                        <a
                          href="https://esloogan.com"
                          target="_blank"
                          rel="noreferrer noopener"
                          style={{ display: "inline-block" }}
                        >
                          Esloogan
                        </a>
                      </strong>
                    </span>
                  </Typography>
                </Box>
              )}
            </MenuItem>
          </Menu>
        </ProSidebar>
      </Box>
    </>
  );
};

export default Sidebar;
