import Header from "../../components/Header";
import { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import { Typography, Box, useTheme, Button } from "@mui/material";
import { API_URL } from "../../data/AppConstants";
import { tokens } from "../../theme";
import CloseIcon from "@mui/icons-material/Close";
import AlertDialog from "../../components/ChooseDialog";

const DeleteAccount = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loginError, setLoginError] = useState(null);
  const [deleteButton, setDeleteButton] = useState(false);
  const [confirmDial, setConfirmDial] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });

  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleCloseDial = () => {
    setConfirmDial(false);
  };
  const handleDelete = () => {
    setConfirmDial(false);
    fetch(`${API_URL}auth/player/${idToDelete}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          showToast("Usuario eliminado exitosamente!", "success");
          setDeleteButton(false);
        } else {
          showToast("Error en el servidor, intentelo más tarde", "error");
        }
      })
      .catch((err) => {
        showToast("Error en el servidor, intentelo más tarde", "error");
        console.log(err);
      });
  };
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      const response = await fetch(`${API_URL}auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          UserName: data.get("email"),
          Password: data.get("password"),
        }),
      });

      const responseData = await response.json();
      if (responseData.ok) {
        setDeleteButton(true);
        setIdToDelete(responseData.userInfo.user_id);
      } else {
        setLoginError(responseData.message);
      }
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
    }
  };

  return (
    <>
      <Box
        m="20px auto"
        width="100%"
        maxWidth="500px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          autoHideDuration={3000}
          open={state.open}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={state.type}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {state.message}
          </Alert>
        </Snackbar>
        <AlertDialog
          open={confirmDial}
          handleClose={handleCloseDial}
          title={"¿Está seguro de borrar su usuario?"}
          content={
            "Se borraran todos los datos asociados a el y no podra recuperarlos"
          }
          handleConfirm={handleDelete}
        />
        <img
          alt="profile-user"
          width="140px"
          height="auto"
          src={`../../assets/logo.svg`}
          style={{ cursor: "pointer", marginBottom: "20px" }}
        />
        <Box mb="30px">
          <Typography
            variant="h2"
            color={colors.grey[100]}
            fontWeight="bold"
            sx={{ m: "0 0 5px 0" }}
            align="center"
          >
            Borrar cuenta
          </Typography>
          <Typography
            variant="h5"
            color={colors.greenAccent[400]}
            align="center"
          >
            Inicia sesión para borrar tu cuenta.
          </Typography>
        </Box>

        {!deleteButton && (
          <>
            <Typography component="h1" variant="h5">
              Inicia sesión
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                color="secondary"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Usuario"
                name="email"
                autoComplete="email"
                color="secondary"
                autoFocus
              />
              <TextField
                color="secondary"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                color="secondary"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: colors.greenAccent[600],
                  "&:hover": {
                    backgroundColor: colors.greenAccent[300], // Color de fondo al hacer hover
                  },
                  color: colors.primary[900],
                  fontWeight: 700,
                }}
              >
                Iniciar sesión
              </Button>
              {loginError && (
                <Typography variant="body2" color="error" align="center">
                  {loginError}
                </Typography>
              )}
            </Box>
          </>
        )}
        {deleteButton && (
          <Button
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: colors.greenAccent[600],
              "&:hover": {
                backgroundColor: colors.greenAccent[300],
              },
              color: colors.primary[900],
              fontWeight: 700,
            }}
            onClick={() => {
              setConfirmDial(true);
            }}
          >
            Borrar cuenta
          </Button>
        )}
      </Box>
    </>
  );
};

export default DeleteAccount;
