import { Box, Typography, useTheme, IconButton, Stack } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "@mui/material/Modal";
import { API_URL } from "../../data/AppConstants";
import { useAuth } from "../../context/AuthContext";
import AlertDialog from "../../components/ChooseDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ModalCreateCoupon from "../../components/cupones/ModalCreateCoupon";
import { Edit, PersonSearch } from "@mui/icons-material";
import ModalEditCoupon from "../../components/cupones/ModalEditCoupon";

const Coupons = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [cupones, setCupones] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [EditCouponModal, setEditCouponModal] = useState({
    state: false,
    coupon: {},
    users: [],
  });
  const { isMobile } = useAuth();
  const [confirmDial, setConfirmDial] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });

  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleCloseToast = () => {
    setState({ ...state, open: false });
  };

  const fetchCouponDetails = async (coupon) => {
    try {
      const response = await fetch(`${API_URL}panel/cupon/${coupon}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      let processedData = data.users.map((item) => ({
        id: item.id,
        email: item.mail,
      }));
      return processedData;
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fecthCupones = () => {
    fetch(`${API_URL}panel/cupones`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.ok) {
          setCupones(data.cupones);
        } else {
          setCupones([]);
        }
      })
      .catch((err) => {
        setCupones([]);
        console.log(err);
      });
  };

  const handleDelete = () => {
    setConfirmDial(false);
    fetch(`${API_URL}panel/cupon/${idToDelete}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          showToast("Cupon eliminado exitosamente!", "success");
          fecthCupones();
        } else {
          showToast("Error en el servidor, intentelo más tarde", "error");
        }
      })
      .catch((err) => {
        showToast("Error en el servidor, intentelo más tarde", "error");
        console.log(err);
      });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditCouponModal({ state: false, id: 0, users: [] });
    fecthCupones();
  };

  const handleCloseDial = () => {
    setConfirmDial(false);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: isMobile ? 1 : "none" },
    {
      field: "couponCode",
      headerName: "Cupon",
      flex: isMobile ? "none" : 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "discount",
      headerName: "Descuento",
      flex: isMobile ? "none" : 1,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => {
        return <Typography>{row.discount} {row.discountType === 'percentage' ? '%' :'€'}</Typography>;
      },
    },
    {
      field: "users",
      headerName: "Usuarios",
      flex: isMobile ? "none" : 1,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => {
        return (
          <IconButton
            onClick={async () => {
              let usrs = await fetchCouponDetails(row.couponCode);
              setEditCouponModal({ state: true, coupon: row, users: usrs });
            }}
            color="secondary"
            aria-label="Ver"
          >
            <PersonSearch />
          </IconButton>
        );
      },
    },
    {
      field: "priceID",
      headerName: "Price ID",
      flex: isMobile ? "none" : 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 160,
      renderCell: ({ row }) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton
              onClick={async () => {
                let usrs = await fetchCouponDetails(row.couponCode);
                setEditCouponModal({ state: true, coupon: row, users: usrs });
              }}
              color="secondary"
              aria-label="Ver"
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => {
                setIdToDelete(row.couponCode);
                setConfirmDial(true);
              }}
              sx={{ color: "red" }}
              aria-label="Eliminar"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    fecthCupones();
  }, []);

  return (
    <Box m="20px">
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity={state.type}
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <Header
        title="Cupones"
        subtitle="Gestiona los cupones"
        showButton={true}
        handleOnClick={handleOpenModal}
      />
      {
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <div>
            <ModalCreateCoupon handleCloseModal={handleCloseModal} />
          </div>
        </Modal>
      }
      {
        <Modal open={EditCouponModal.state} onClose={handleCloseModal}>
          <div>
            <ModalEditCoupon
              handleCloseModal={handleCloseModal}
              coupon={EditCouponModal.coupon}
              users={EditCouponModal.users}
            />
          </div>
        </Modal>
      }

      <AlertDialog
        open={confirmDial}
        handleClose={handleCloseDial}
        title={"¿Está seguro de borrar este Cupon?"}
        content={"No se podra recuperar"}
        handleConfirm={handleDelete}
      />
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: `${colors.primary[900]} !important`,
            fontWeight: 700,
            fontSize: "14px",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiTablePagination-toolbar": {
            color: "#000 !important",
          },
          "& .MuiDataGrid-selectedRowCount": {
            color: "#000 !important",
          },
        }}
      >
        <DataGrid
          rows={cupones}
          columns={columns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          components={{
            NoResultsOverlay: () => (
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center"
                zIndex="999"
                position="relative"
              >
                No hay resultados para el filtro aplicado
              </Stack>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default Coupons;
