import Header from "../../components/Header";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  Typography,
  Box,
  useTheme,
  FormControl,
  InputLabel,
  Input,
  FormGroup,
  FormControlLabel,
  Grid,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import {
  API_URL,
  countries,
  provincesByCountry,
} from "../../data/AppConstants";
import { tokens } from "../../theme";
import { useAuth } from "../../context/AuthContext";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import esLocale from "dayjs/locale/es";

dayjs.locale(esLocale);

const CreateAgent = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { isMobile } = useAuth();
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });

  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const [formValues, setFormValues] = useState({
    name: "",
    lastname: "",
    email: "",
    country: "España",
    state: "",
    entidad_deportiva: "",
    date: dayjs(),
    password: "",
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleChangeDate = (value, newdate) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [value]: newdate,
    }));
  };

  const handleCreateAgent = () => {
    if (
      formValues.name === "" ||
      formValues.lastname === "" ||
      formValues.email === "" ||
      formValues.country === "" ||
      formValues.state === "" ||
      formValues.password === ""
    ) {
      showToast("Por favor, complete todos los campos.", "error");
    } else {
      fetch(`${API_URL}auth/panel/agent-nuevo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            setFormValues({
              name: "",
              lastname: "",
              email: "",
              country: "España",
              state: "",
              date: dayjs(),
              password: "",
            });
            showToast(
              "Su cuenta ha sido creada, su información sera revisada por uno de nuestros administradores y le llegara la confirmacion por correo.",
              "success"
            );
          } else {
            showToast(data.error, "error");
          }
        })
        .catch((err) => {
          console.log(err);
          setFormValues({
            name: "",
            lastname: "",
            email: "",
            country: "España",
            state: "",
            date: dayjs(),
            password: "",
          });
          showToast("Error en el servidor, intentelo más tarde", "error");
        });
    }
  };
  return (
    <Box
      m="20px auto"
      width="100%"
      maxWidth="500px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <img
        alt="profile-user"
        width="140px"
        height="auto"
        src={`../../assets/logo.svg`}
        style={{ cursor: "pointer", marginBottom: "20px" }}
      />
      <Header
        title="Creación de cuenta de Agente"
        subtitle="Un administrador debera aprobar tu cuenta para que puedas comenzar a usarla."
      />
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel color="secondary" htmlFor="name">Nombre</InputLabel>
        <Input
          id="name"
          name="name"
          value={formValues.name}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel color="secondary" htmlFor="lastname">Apellidos</InputLabel>
        <Input
          id="lastname"
          name="lastname"
          value={formValues.lastname}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel color="secondary" htmlFor="email">Correo</InputLabel>
        <Input
          id="email"
          name="email"
          value={formValues.email}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel color="secondary" htmlFor="entidad_deportiva">Entidad Deportiva</InputLabel>
        <Input
          id="entidad_deportiva"
          name="entidad_deportiva"
          value={formValues.entidad_deportiva}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
        <InputLabel color="secondary" htmlFor="password">Contraseña</InputLabel>
        <Input
          id="password"
          name="password"
          type="password"
          value={formValues.password}
          onChange={handleChange}
        />
      </FormControl>
      <Box sx={{ width: "100%" }}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          locale={esLocale}
          adapterLocale="es"
        >
          <DemoContainer components={["DatePicker"]}>
            <DemoItem label="Fecha de nacimiento">
              <DatePicker
                value={formValues.date}
                onChange={(newValue) => handleChangeDate("date", newValue)}
                format="DD/MM/YYYY"
                localeText={{
                  clearButtonLabel: "Vaciar",
                  todayButtonLabel: "Hoy",
                  okButtonLabel: "Guardar",
                  cancelButtonLabel: "Cancelar",
                  toolbarTitle: "Selecciona fecha y hora",
                }}
                slotProps={{
                  color: "#fcfcfc",

                  clearIcon: {
                    style: {
                      color: "#000000",
                    },
                  },
                }}
                style={{ width: 500 }}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
      </Box>
      <Box sx={{ mb: 2, mt: 2, width: "100%" }}>
        <InputLabel color="secondary"
          sx={{
            fontSize: "15px",
          }}
        >
          País
        </InputLabel>
        <FormControl fullWidth>
          <Select
            color="secondary"

            id="country"
            name="country"
            value={formValues.country}
            onChange={handleChange}
            variant="outlined"
          >
            {countries.map((country) => {
              return (
                <MenuItem
                  key={`pais-${country}`}
                  value={country}
                  style={{
                    backgroundColor:
                      formValues.country === country
                        ? colors.greenAccent[500]
                        : "transparent",
                    color: formValues.country === country ? "#000" : "#fff",
                  }}
                >
                  {country}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mb: 2, width: "100%" }}>
        <InputLabel color="secondary"
          sx={{
            fontSize: "15px",
          }}
        >
          Provincia
        </InputLabel>
        <FormControl fullWidth>
          <Select
            color="secondary"

            id="state"
            name="state"
            value={formValues.state}
            onChange={handleChange}
            variant="outlined"
          >
            {provincesByCountry[formValues.country].map((state) => {
              return (
                <MenuItem
                  key={`provincia-${state}`}
                  value={state}
                  style={{
                    backgroundColor:
                      formValues.state === state
                        ? colors.greenAccent[500]
                        : "transparent",
                    color: formValues.state === state ? "#000" : "#fff",
                  }}
                >
                  {state}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Button variant="contained" color="secondary" onClick={handleCreateAgent}>
        Crear
      </Button>
    </Box>
  );
};

export default CreateAgent;
