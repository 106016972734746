import { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  Typography,
  Box,
  useTheme,
  FormControl,
  InputLabel,
  Input,
  Button,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Checkbox,
  Chip,
  CircularProgress,
} from "@mui/material";
import { API_URL } from "../../data/AppConstants";
import { tokens } from "../../theme";
import { useAuth } from "../../context/AuthContext";
import dayjs from "dayjs";
import esLocale from "dayjs/locale/es";
dayjs.locale(esLocale);

const ModalEditCoupon = ({ handleCloseModal, coupon, users }) => {
  const [formValues, setFormValues] = useState({
    code: "",
    discount: coupon.discount,
    discountType: coupon.discountType,
    users: users,
    priceID: {},
  });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { isMobile } = useAuth();
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
    requestsStatusDetails: false,
    requestsStatusUsers: false,
    requestsStatusProducts: false,

    usersForDropDown: [],
    productsForDropDown: [],
  });

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${API_URL}user/users`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      let processedData = data.users.map((item) => ({
        id: item.id,
        email: item.mail,
      }));
      setState((prevState) => ({
        ...prevState,
        usersForDropDown: processedData,
        requestsStatusUsers: true,
      }));
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await fetch(`${API_URL}course/products`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      let processedData = data.products.map((item) => ({
        id: item.id,
        product: item.name,
        priceId: item.stripe_price_id,
      }));

      let selectedProduct = processedData.find(
        (item) => item.priceId === coupon.priceID
      );

      setFormValues({
        ...formValues,
        priceID: selectedProduct || null, // Set to null if not found
      });

      setState((prevState) => ({
        ...prevState,
        productsForDropDown: processedData,
        requestsStatusProducts: true,
      }));
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchProducts();
    console.log(formValues);
  }, []);

  const showToast = (message, type) => {
    setState({ ...state, open: true, message, type });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleChange = (event, newValue) => {
    const { name, value, type, checked } = event.target || {};
    // Handling standard input changes
    if (name) {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: type === "checkbox" ? checked : value,
      }));
    }

    // Handling Autocomplete changes for users
    if (name === "users") {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        users: newValue,
      }));
    }

    // Handling Autocomplete changes for priceID
    if (name === "priceID") {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        priceID: newValue,
      }));
    }
  };

  const handleUpdateCoupon = () => {
    if (formValues.discount === "") {
      showToast("Por favor, complete todos los campos.", "error");
    } else {
      console.log(formValues);
      let userIdList = formValues.users.map((item) => item.id);
      fetch(`${API_URL}panel/cupon/${coupon.couponCode}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: coupon.couponCode,
          discount: formValues.discount,
          priceID: formValues.priceID.priceId,
          discountType: formValues.discountType,
          users: userIdList,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            showToast(data.message, "success");
            setTimeout(() => {
              handleCloseModal();
            }, 1000);
          } else {
            showToast(data.error, "error");
          }
        })
        .catch((err) => {
          console.log(err);
          showToast("Error en el servidor, intentelo más tarde", "error");
        });
    }
  };
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: colors.primary[800],
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
        minWidth: "400px",
        overflowY: isMobile ? "scroll" : "none",
        height: isMobile ? "80%" : "auto",
      }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <Typography variant="h4" mb={2}>
        Editar Cupon {coupon.couponCode}
      </Typography>
      {/* <FormControl fullWidth sx={{ mb: 2 }} >
                <InputLabel    color="secondary" htmlFor="code">Codigo del cupon</InputLabel>
                <Input
                    id="code"
                    name="code"
                    value={formValues.code}
                    onChange={handleChange}
                />
            </FormControl> */}

      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel color="secondary" id="discountType-label">
          Tipo de descuento
        </InputLabel>
        <Select
          color="secondary"
          labelId="discountType-label"
          id="discountType"
          name="discountType"
          value={formValues.discountType}
          onChange={handleChange}
          label="Tipo de descuento"
        >
          <MenuItem key={1} value="percentage">
            %
          </MenuItem>
          <MenuItem key={2} value="currency">
            €
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel color="secondary" htmlFor="discount">
          Descuento {formValues.discountType === "percentage" ? "0-100" : ""}
        </InputLabel>
        <Input
          id="discount"
          name="discount"
          value={formValues.discount}
          onChange={handleChange}
        />
      </FormControl>

      <FormControl fullWidth sx={{ mb: 2 }}>
        {state.productsForDropDown.length > 0 && (
          <Autocomplete
            multiple
            id="users"
            options={state.usersForDropDown}
            disableCloseOnSelect
            getOptionLabel={(option) => option.email}
            defaultValue={formValues.users}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} style={{ marginRight: 8 }} />
                {option.email}
              </li>
            )}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                color="secondary"
                {...params}
                label="Seleccione sus usuarios"
                placeholder="ejemplo@ejemplo.com"
              />
            )}
            onChange={(event, newValue) =>
              handleChange({ target: { name: "users" } }, newValue)
            }
            renderTags={(selected, getTagProps) =>
              selected.map((option, index) => (
                <Chip
                  key={index}
                  label={option.email}
                  {...getTagProps({ index })}
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                  }}
                />
              ))
            }
          />
        )}
      </FormControl>

      <FormControl fullWidth sx={{ mb: 2 }}>
        {state.productsForDropDown.length > 0 && (
          <Autocomplete
            fullWidth
            sx={{ mb: 2 }}
            id="priceID"
            options={state.productsForDropDown}
            getOptionLabel={(option) => (option ? option.product : "")}
            defaultValue={
              state.productsForDropDown.find(
                (option) => option.priceId === formValues.priceID?.priceId
              ) || null
            }
            isOptionEqualToValue={(option, value) =>
              option.priceId === value.priceId
            }
            renderInput={(params) => (
              <TextField
                color="secondary"
                {...params}
                label="Seleccione un producto*"
                placeholder="Trading Master Course"
              />
            )}
            onChange={(event, newValue) =>
              handleChange({ target: { name: "priceID" } }, newValue)
            }
            style={{ width: "100%" }}
          />
        )}
      </FormControl>

      <Box display="flex" justifyContent="space-around" mt={3}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleUpdateCoupon}
        >
          Guardar
        </Button>
        <Button variant="contained" onClick={handleCloseModal}>
          Cerrar
        </Button>
      </Box>
    </Box>
  );
};

export default ModalEditCoupon;
