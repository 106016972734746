import React from "react";
import { CirclesWithBar } from "react-loader-spinner";

const Loader = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transform: "translate(-50%, -50%)",
        zIndex: 9999,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        padding: 20,
        borderRadius: 4,
      }}
    >
      <CirclesWithBar
        height="100"
        width="100"
        color="#05ff00"
        outerCircleColor="#05ff00"
        innerCircleColor="#05ff00"
        barColor="#05ff00"
        ariaLabel="circles-with-bar-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default Loader;
