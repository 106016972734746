import { Box, useTheme, IconButton, Stack, Typography } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../data/AppConstants";
import { useAuth } from "../../context/AuthContext";
import AlertDialog from "../../components/ChooseDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import EditCourseForm from "./EditCourses";

const Courses = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [EditForm, setEditForm] = useState({ status: false, course: {} });

  const { isMobile } = useAuth();
  const [confirmDial, setConfirmDial] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });

  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleCloseToast = () => {
    setState({ ...state, open: false });
  };

  const fetchCourses = () => {
    fetch(`${API_URL}course/courses`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          setCourses(data.courses);
        } else {
          setCourses([]);
        }
      })
      .catch((err) => {
        setCourses([]);
        console.log(err);
      });
  };


  const handleDelete = () => {
    setConfirmDial(false);
    fetch(`${API_URL}course/course/${idToDelete}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          showToast("Curso eliminado exitosamente!", "success");
          fetchCourses();
        } else {
          showToast("Error en el servidor, intentelo más tarde", "error");
        }
      })
      .catch((err) => {
        showToast("Error en el servidor, intentelo más tarde", "error");
        console.log(err);
      });
  };

  const handleCloseDial = () => {
    setConfirmDial(false);
  };

  const handleCloseEditForm = () => {
    fetchCourses();
    setEditForm({ status: false, course: {} })
  }

  const columns = [
    { field: "id", headerName: "ID", flex: isMobile ? 1 : "none" },
    {
      field: "title",
      headerName: "Nombre",
      flex: isMobile ? "none" : 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "lessons",
      headerName: "Numero de lecciones",
      flex: isMobile ? "none" : 1,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => {
        return (
          <Typography variant="h5" mb={2}>
            {row.lessons.length}
          </Typography>
        );
      },
    },
    {
      field: "isActive",
      headerName: "Activo",
      headerAlign: "center",
      align: "center",
      flex: isMobile ? "none" : 1,
      renderCell: ({ row: { isActive } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor="transparent"
            borderRadius="4px"
          >
            {isActive ? (
              <CheckIcon style={{ color: "green" }} />
            ) : (
              <ClearIcon style={{ color: "red" }} />
            )}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {isActive ? "Activo" : "Inactivo"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "isFeatured",
      headerName: "Destacado",
      headerAlign: "center",
      align: "center",
      flex: isMobile ? "none" : 1,
      renderCell: ({ row: { isFeatured } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor="transparent"
            borderRadius="4px"
          >
            {isFeatured ? (
              <CheckIcon style={{ color: "green" }} />
            ) : (
              <ClearIcon style={{ color: "red" }} />
            )}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {isFeatured ? "Destacado" : "No destacado"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "isPaid",
      headerName: "De pago",
      headerAlign: "center",
      align: "center",
      flex: isMobile ? "none" : 1,
      renderCell: ({ row: { isPaid } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor="transparent"
            borderRadius="4px"
          >
            {isPaid ? (
              <CheckIcon style={{ color: "green" }} />
            ) : (
              <ClearIcon style={{ color: "red" }} />
            )}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {isPaid ? "De pago" : "Gratis"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "amount",
      headerName: "Valor",
      headerAlign: "center",
      align: "center",
      flex: isMobile ? "none" : 1,
      renderCell: ({ row }) => {
        return (
          <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
            {row.amount} € / {row.recurring}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 160,
      renderCell: ({ row }) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton
              onClick={() => setEditForm({ status: true, course: row })}
              color="secondary"
              aria-label="Editar"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setIdToDelete(row.id);
                setConfirmDial(true);
              }}
              color="secondary"
              aria-label="Eliminar"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    fetchCourses();
  }, []);

  return (
    <Box m="20px">
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity={state.type}
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      {!(EditForm.status) ? <Header
        title="Cursos"
        subtitle="Gestiona las cursos de la academia"
        showButton={true}
        handleOnClick={() => navigate("/create-courses")}
      /> : null}

      <AlertDialog
        open={confirmDial}
        handleClose={handleCloseDial}
        title={"¿Está seguro de borrar esta Curso?"}
        content={"Se borraran todos los datos asociados a el"}
        handleConfirm={handleDelete}
      />

      {EditForm.status ? (
        <EditCourseForm course={EditForm.course} handleCloseForm={handleCloseEditForm} />
      ) : (
        <Box
          m="10px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              color: `${colors.primary[900]} !important`,
              fontWeight: 700,
              fontSize: "14px",
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiTablePagination-toolbar": {
              color: "#000 !important",
            },
            "& .MuiDataGrid-selectedRowCount": {
              color: "#000 !important",
            },
          }}
        >
          <DataGrid
            rows={courses}
            columns={columns}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            components={{
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                  zIndex="999"
                  position="relative"
                >
                  No hay resultados para el filtro aplicado
                </Stack>
              ),
            }}
          />
        </Box>



      )}

    </Box>
  );
};

export default Courses;
