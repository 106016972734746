import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import PrivateRoute from "./utils/PrivateRoute";
import Login from "./scenes/Login/Login";
import { useAuth } from "./context/AuthContext";
import Cookies from "js-cookie";
import CreateAgent from "./scenes/agents/Create_Agent";
import Coupons from "./scenes/coupons/Coupons";
import UsuariosPanel from "./scenes/admin/Usuarios";
import CreatePlayer from "./scenes/team/Create_Player";
import DeleteAccount from "./scenes/delete/DeleteAccount";
import CreateCourseForm from "./scenes/courses/CreateCourses";
import Category from "./scenes/categorys/Category";
import Products from "./scenes/products/Products";
import Users from "./scenes/team";
import Courses from "./scenes/courses/Courses";
import Senales from "./scenes/senales/senales";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const { isAuthenticated, login } = useAuth();

  useEffect(() => {
    // Verificar si la cookie de autenticación está presente al cargar la aplicación
    const isLoggedIn = Cookies.get("isLoggedIn");

    if (isLoggedIn === "true") {
      const isAdmin = JSON.parse(Cookies.get("isAdmin"));
      const userId = JSON.parse(Cookies.get("userId"));
      login(isAdmin, userId);
    }
  }, []);
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {isAuthenticated && <Sidebar isSidebar={isSidebar} />}
          <main
            className="content"
            style={{
              overflowY: "scroll",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {isAuthenticated && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/new-agent" element={<CreateAgent />} />
              <Route path="/eliminar-cuenta" element={<DeleteAccount />} />
              <Route path="/ref/:referralCode" element={<CreatePlayer />} />
              <Route element={<PrivateRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/courses" element={<Courses />} />
                <Route path="/create-courses" element={<CreateCourseForm />} />
                <Route path="/categories" element={<Category />} />
                <Route path="/products" element={<Products />} />
                <Route path="/users" element={<Users />} />
                <Route path="/coupons" element={<Coupons />} />
                <Route path="/senales" element={<Senales />} />

                <Route path="/panel-users" element={<UsuariosPanel />} />
              </Route>
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
